import React, { useState } from "react";
import "../../styles/booking-form.css";
import { Form, FormGroup } from "reactstrap";

const BookingForm = ({ onBookingSubmit }) => {
  const [bookingData, setBookingData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    fromAddress: "",
    toAddress: "",
    persons: "1 person",
    luggage: "1 luggage",
    journeyDate: "",
    journeyTime: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBookingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const submitHandler = (event) => {
    event.preventDefault();
    onBookingSubmit(bookingData);
  };

  return (
    <Form onSubmit={submitHandler}>
      {/* First Name */}
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input
          type="text"
          placeholder="First Name"
          name="firstName"
          value={bookingData.firstName}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {/* Last Name */}
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input
          type="text"
          placeholder="Last Name"
          name="lastName"
          value={bookingData.lastName}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {/* Email */}
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={bookingData.email}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {/* Phone Number */}
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input
          type="number"
          placeholder="Phone Number"
          name="phoneNumber"
          value={bookingData.phoneNumber}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {/* From Address */}
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input
          type="text"
          placeholder="From Address"
          name="fromAddress"
          value={bookingData.fromAddress}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {/* To Address */}
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input
          type="text"
          placeholder="To Address"
          name="toAddress"
          value={bookingData.toAddress}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {/* Persons */}
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <select
          name="persons"
          value={bookingData.persons}
          onChange={handleChange}
          required
        >
          <option value="1 person">1 Person</option>
          <option value="2 person">2 Persons</option>
          <option value="3 person">3 Persons</option>
          <option value="4 person">4 Persons</option>
          <option value="5+ person">5+ Persons</option>
        </select>
      </FormGroup>
      {/* Luggage */}
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <select
          name="luggage"
          value={bookingData.luggage}
          onChange={handleChange}
          required
        >
          <option value="1 luggage">1 Luggage</option>
          <option value="2 luggage">2 Luggages</option>
          <option value="3 luggage">3 Luggages</option>
          <option value="4 luggage">4 Luggages</option>
          <option value="5+ luggage">5+ Luggages</option>
        </select>
      </FormGroup>
      {/* Journey Date */}
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input
          type="date"
          placeholder="Journey Date"
          name="journeyDate"
          value={bookingData.journeyDate}
          onChange={handleChange}
          required
        />
      </FormGroup>
      {/* Journey Time */}
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input
          type="time"
          placeholder="Journey Time"
          name="journeyTime"
          value={bookingData.journeyTime}
          onChange={handleChange}
          className="time__picker"
          required
        />
      </FormGroup>
      {/* Message */}
      <FormGroup>
        <textarea
          rows={5}
          type="textarea"
          className="textarea"
          placeholder="Write"
          name="message"
          value={bookingData.message}
          onChange={handleChange}
        ></textarea>
      </FormGroup>
      {/* Submit Button */}
      <button type="submit" className="btn btn-primary">
        Proceed to Payment
      </button>
    </Form>
  );
};

export default BookingForm;
