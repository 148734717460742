import { initializeApp } from "firebase/app";
import { getFirestore, serverTimestamp } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB7Bu4ujGvyGHDXti96TaR0ol8RQt6tjTk",
  authDomain: "vipcars-997ad.firebaseapp.com",
  projectId: "vipcars-997ad",
  storageBucket: "vipcars-997ad.appspot.com",
  messagingSenderId: "231513502673",
  appId: "1:231513502673:web:cee5320e06ab5abe9ca6f0",
  measurementId: "G-XVJXMYNML0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

// Export the Firestore instance and serverTimestamp function
export { db, serverTimestamp };
