// import all images from assets/images directory
import img01 from "../all-images/cars-img/Audi-Q5.png";
import img02 from "../all-images/cars-img/Land-Rover.png";

const carData = [
  // New car - Audi Q5
  {
    id: 1,
    brand: "Audi",
    rating: 85,
    carName: "Audi Q5",
    imgUrl: img01, // Use the correct image path
    model: "2019",
    price: 190,
    speed: "18kmpl",
    gps: "GPS Navigation",
    seatType: "Heated seats",
    automatic: "Automatic",
    description:
      "The Audi Q5 combines sporty characteristics with a comfortable and spacious interior. Perfect for long journeys or city driving.",
  },

  // New car - Land Rover Discovery
  {
    id: 2,
    brand: "Land Rover",
    rating: 90,
    carName: "Land Rover Discovery",
    imgUrl: img02, // Use the correct image path
    model: "2019",
    price: 240,
    speed: "15kmpl",
    gps: "GPS Navigation",
    seatType: "Luxury seats",
    automatic: "Automatic",
    description:
      "The Land Rover Discovery is built for adventure. Its powerful engine and 4WD system ensure excellent performance in all terrains.",
  },
];

export default carData;
