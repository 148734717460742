// PaymentMethod.jsx

import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { db } from "../../firebase"; // Adjust path as needed
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import "../../styles/payment-method.css"; // Adjust your CSS file path

// Load Stripe using the publishable key from environment variables
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutForm = ({ amount, bookingData, carItem }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [clientSecret, setClientSecret] = useState("");
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    // Fetch the client secret from your backend (Cloud Function)
    fetch(
      "https://<your-region>-<your-project>.cloudfunctions.net/createPaymentIntentNZD",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ amount }), // amount in cents
      }
    )
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret))
      .catch((error) => console.error("Error fetching client secret:", error));
  }, [amount]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements || !clientSecret) {
      setProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: `${bookingData.firstName} ${bookingData.lastName}`,
              email: bookingData.email,
              phone: bookingData.phoneNumber,
            },
          },
        }
      );

      if (error) {
        console.error("[Payment error]", error);
        alert(`Payment failed: ${error.message}`);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        console.log("Payment succeeded:", paymentIntent);
        // Save booking details to Firestore
        const bookingId = await saveBookingToFirestore(paymentIntent.id);

        // Send confirmation emails to both the user and the client
        await sendConfirmationEmail(bookingId, bookingData);

        alert(
          "Payment succeeded! Booking confirmed. A confirmation email has been sent."
        );
        // You could redirect or update UI as needed here
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      alert("An unexpected error occurred.");
    } finally {
      setProcessing(false);
    }
  };

  const saveBookingToFirestore = async (paymentIntentId) => {
    try {
      const docRef = await addDoc(collection(db, "bookings"), {
        carId: carItem.id,
        carName: carItem.carName,
        userName: `${bookingData.firstName} ${bookingData.lastName}`,
        email: bookingData.email,
        phoneNumber: bookingData.phoneNumber,
        fromAddress: bookingData.fromAddress,
        toAddress: bookingData.toAddress,
        persons: bookingData.persons,
        luggage: bookingData.luggage,
        journeyDate: bookingData.journeyDate,
        journeyTime: bookingData.journeyTime,
        message: bookingData.message,
        amount: amount / 100, // Convert cents to dollars
        paymentIntentId: paymentIntentId,
        timestamp: serverTimestamp(),
        status: "paid", // You can mark the booking as paid
      });
      console.log("Booking saved successfully with ID:", docRef.id);
      return docRef.id;
    } catch (error) {
      console.error("Error saving booking:", error);
      throw error;
    }
  };

  const sendConfirmationEmail = async (bookingId, bookingData) => {
    try {
      const response = await fetch(
        "https://<your-region>-<your-project>.cloudfunctions.net/sendConfirmationEmail",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            bookingId: bookingId,
            userEmail: bookingData.email,
            clientEmail: "client@yourclientdomain.com",
          }),
        }
      );

      if (!response.ok) {
        console.error("Failed to send email confirmation");
      } else {
        console.log("Confirmation email sent successfully");
      }
    } catch (error) {
      console.error("Error sending confirmation email:", error);
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: "#1a1a40",
        fontFamily: "Arial, sans-serif",
        fontSize: "16px",
        "::placeholder": {
          color: "#999",
        },
      },
      invalid: {
        color: "#e32129",
      },
    },
    hidePostalCode: true,
  };

  return (
    <form onSubmit={handleSubmit} className="payment__form">
      <CardElement options={cardStyle} className="payment__card-element" />
      <button
        type="submit"
        disabled={!stripe || processing}
        className="payment__button"
      >
        {processing ? "Processing..." : `Pay $${(amount / 100).toFixed(2)}`}
      </button>
    </form>
  );
};

const PaymentMethod = ({ amount, bookingData, carItem }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        amount={amount}
        bookingData={bookingData}
        carItem={carItem}
      />
    </Elements>
  );
};

export default PaymentMethod;
