import React from "react";
import { Col } from "reactstrap";
import "../../styles/services-list.css";

const servicesData = [
  {
    id: 1,
    title: "Flexible Rental Plans",
    icon: "ri-calendar-line",
    desc: "Rent a car on your terms with daily, weekly, and monthly options to suit your schedule.",
  },
  {
    id: 2,
    title: "Affordable Rental Rates",
    icon: "ri-money-dollar-circle-line",
    desc: "Enjoy competitive pricing with transparent fees and no hidden costs.",
  },
  {
    id: 3,
    title: "24/7 Customer Support",
    icon: "ri-customer-service-2-line",
    desc: "Our support team is available around the clock to assist you with any needs.",
  },
];

const ServicesList = () => {
  return (
    <>
      {servicesData.map((item) => (
        <ServiceItem item={item} key={item.id} />
      ))}
    </>
  );
};

const ServiceItem = ({ item }) => (
  <Col lg="4" md="4" sm="6" className="mb-3 text-center">
    <div className="service__item">
      <span className="mb-3 d-inline-block">
        <i className={item.icon} />
      </span>

      <h6 className="service__title">{item.title}</h6>
      <p className="section__description">{item.desc}</p>
    </div>
  </Col>
);

export default ServicesList;
