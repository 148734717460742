// import images from all-images/blog-img directory
import img01 from "../all-images/blog-img/blog-1.jpg";
import img02 from "../all-images/blog-img/blog-2.jpg";
import img03 from "../all-images/blog-img/blog-3.jpg";

const blogData = [
  {
    id: 1,
    title: "The best way to drive cars",
    author: "Ethan",
    date: "12 Jan, 2024",
    time: "9pm",
    imgUrl: img01,
    description:
      "Discover essential tips and techniques for driving cars safely and efficiently. From mastering basic controls to understanding advanced driving strategies, this guide will help you become a more confident driver on the road.",
    quote:
      "Driving isn't just about reaching your destination—it's about enjoying the journey safely and responsibly.",
  },

  {
    id: 2,
    title: "If your car battery is down",
    author: "Mel",
    date: "12 March, 2024",
    time: "9pm",
    imgUrl: img02,
    description:
      "Learn what steps to take when your car battery dies. This article covers how to jump-start your vehicle, recognize the signs of a failing battery, and preventive measures to keep your car running smoothly.",
    quote:
      "A dead battery doesn't have to halt your journey; with the right knowledge, you can be back on the road in no time.",
  },

  {
    id: 3,
    title: "The best way to give trip",
    author: "Ethan",
    date: "12 Dec, 2024",
    time: "9pm",
    imgUrl: img03,
    description:
      "Planning to gift someone an unforgettable trip? Discover the best ways to organize and present a travel experience as the perfect gift. From choosing the ideal destination to handling all the logistics, make their journey truly special.",
    quote:
      "The gift of travel is the gift of new experiences, memories, and a broadened horizon.",
  },
];

export default blogData;
